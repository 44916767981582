// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { CloseOutlined } from '@ant-design/icons';

// types
interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AlertBuildingDeleteFail({ open, handleClose }: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <CloseOutlined />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h2" align="center">
              Удаление невозможно
            </Typography>
            <Typography variant="h6" align="center">
              Имеются связанные сущности
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={handleClose} color="secondary" variant="outlined">
              Понятно
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
