// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { DeleteFilled } from '@ant-design/icons';
import { useCallback } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { deleteBuilding } from 'store/reducers/buildingsSlice';

// types
interface Props {
  id: string;
  open: boolean;
  handleClose: () => void;
  error?: any;
}

export default function AlertBuildingDelete({ id, open, handleClose, error }: Props) {
  const handleDelete = useCallback(
    (id: string) => {
      dispatch(deleteBuilding(id));
      if (!error) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Адрес успешно удален.',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Адрес имеет связанные сущности',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
      handleClose();
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [id]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <DeleteFilled />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h2" align="center">
              Вы уверены, что хотите удалить?
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={handleClose} color="secondary" variant="outlined">
              Отмена
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                id && handleDelete && handleDelete(id);
              }}
              autoFocus
            >
              Удалить
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
