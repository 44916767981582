import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { IState, IResponse, IUser, INewClient, IEditClient, IClient, IResponseClient } from 'types/clients';

const initialState: IState = {
  clients: [],
  client: null,
  users: [],
  loading: false
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    allClients(state, action: PayloadAction<IClient[]>) {
      state.clients = action.payload;
    },

    oneClient(state, action: PayloadAction<IClient | null>) {
      state.client = action.payload;
    },

    newClient(state, action: PayloadAction<IClient>) {
      state.clients = [...state.clients, action.payload];
    },

    editClient(state, action: PayloadAction<IClient>) {
      state.clients = [...state.clients.filter((item) => item.id !== action.payload.id), action.payload];
    },

    allUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    removeClient(state, action: PayloadAction<number>) {
      state.clients = state.clients.filter((item) => item.id !== action.payload);
    }
  }
});

export default clientsSlice.reducer;

export const { oneClient } = clientsSlice.actions;

export function getClients() {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IClient[]>>(`${process.env.REACT_APP_API_URL}/clients`);
      dispatch(clientsSlice.actions.allClients(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}

export function getClient(id: number) {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IClient>(`${process.env.REACT_APP_API_URL}/clients/${id}`);
      dispatch(clientsSlice.actions.oneClient(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}

export function getAllUsers(role?: string) {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      const response = role
        ? await axios.get<IResponse<IUser[]>>(`${process.env.REACT_APP_API_URL}/users?eRoles.code=${role}`)
        : await axios.get<IResponse<IUser[]>>(`${process.env.REACT_APP_API_URL}/users`);
      dispatch(clientsSlice.actions.allUsers(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}

export function createClient(body: INewClient, users: IUser[]) {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      const response = await axios.post<IResponseClient>(`${process.env.REACT_APP_API_URL}/clients`, body);
      dispatch(clientsSlice.actions.newClient(response.data));
      users.forEach(
        async (user) =>
          await axios.patch<IUser>(
            `${process.env.REACT_APP_API_URL}/users/${user.id}`,
            { client: response.data['@id'] },
            {
              headers: {
                'Content-Type': 'application/merge-patch+json'
              }
            }
          )
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}

export function editClient(body: IEditClient, users: IUser[], initUsers: IUser[]) {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      const response = await axios.patch<IClient>(`${process.env.REACT_APP_API_URL}/clients/${body.id}`, body, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      });
      for (let i = 0; i < initUsers.length; i++) {
        const user = initUsers[i];
        await axios.patch<IUser>(
          `${process.env.REACT_APP_API_URL}/users/${user.id}`,
          { client: null },
          {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          }
        );
      }
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        await axios.patch<IUser>(
          `${process.env.REACT_APP_API_URL}/users/${user.id}`,
          { client: response.data['@id'] },
          {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          }
        );
      }

      dispatch(clientsSlice.actions.editClient(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}

export function deleteClient(id: number) {
  return async () => {
    dispatch(clientsSlice.actions.setLoading(true));
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/clients/${id}`);
      dispatch(clientsSlice.actions.removeClient(id));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clientsSlice.actions.setLoading(false));
    }
  };
}
