import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';

// project imports
import AlertBuildingGroupDelete from './AlertBuildingDelete';
import IconButton from 'components/@extended/IconButton';

import { dispatch, useSelector, RootState } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

// assets
import { CameraOutlined, DeleteFilled } from '@ant-design/icons';

// types
import { ThemeMode } from 'types/config';

import { useParams } from 'react-router';
import { createBuilding, updateBuilding } from 'store/reducers/buildingsSlice';
import { BuildingGroup } from 'types/buildingGroup';
import AlertBuildingDeleteFail from './AlertBuildingDeleteFail';

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

export interface Props {
  buildingGroup?: any;
  onCancel: () => void;
  error: any;
}

const AddBuilding = ({ buildingGroup, onCancel, error }: Props) => {
  const theme = useTheme();
  const isCreating = !buildingGroup;

  const CustomerSchema = Yup.object().shape({
    title: Yup.string().max(255).required('* Обязательное поле'),
    buildingGroup: Yup.string().required('* Обязательное поле'),
    entrances: Yup.string().max(500)
  });

  const { id } = useParams();

  const [openAlert, setOpenAlert] = useState(false);
  const building = useSelector((state: RootState) => state.buildingsGroupsSlice) || [];

  const getInitialValues = (buildingGroup: FormikValues | null) => {
    const newCustomer = {
      title: '',
      buildingGroup:
        building.buildingGroups.find((item) => item.id === +window.location.href.split('/')[window.location.href.split('/').length - 1])?.[
          'title'
        ] || '',
      entrances: ''
    };

    if (buildingGroup) {
      // newCustomer.title = buildingGroup.title;
      // newCustomer.buildingGroup = buildingGroup.buildingGroup;
      // newCustomer.entrances = buildingGroup.entrances;
      return _.merge({}, newCustomer, buildingGroup);
    }

    return newCustomer;
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
    onCancel();
  };

  const formik = useFormik({
    initialValues: getInitialValues(buildingGroup!),
    validationSchema: CustomerSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const buildingGroupId = building.buildingGroups.find((item) => item.title === values.buildingGroup)?.['@id'] || '';
        const newCustomer = {
          title: values.title,
          buildingGroup: buildingGroupId,
          entrances: values.entrances
        };

        if (buildingGroup) {
          dispatch(updateBuilding(buildingGroup.id, newCustomer));
          !error &&
            dispatch(
              openSnackbar({
                open: true,
                message: 'Адрес успешно обновлен.',
                variant: 'alert',
                alert: {
                  color: 'success'
                },
                close: false
              })
            );
        } else {
          id && dispatch(createBuilding(id, newCustomer));
          !error &&
            dispatch(
              openSnackbar({
                open: true,
                message: 'Адрес Успешно добавлен.',
                variant: 'alert',
                alert: {
                  color: 'success'
                },
                close: false
              })
            );
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{buildingGroup ? 'Редактировать Адрес' : 'Новый Адрес'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                    <FormLabel
                      htmlFor="change-avtar"
                      sx={{
                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        '&:hover .MuiBox-root': { opacity: 1 },
                        cursor: 'pointer'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Stack spacing={0.5} alignItems="center">
                          <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                          <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
                        </Stack>
                      </Box>
                    </FormLabel>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="title">Адрес</InputLabel>
                        <TextField
                          fullWidth
                          id="title"
                          placeholder="Укажите адрес"
                          {...getFieldProps('title')}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="buildingGroup">ЖК</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="buildingGroup"
                            displayEmpty
                            {...getFieldProps('buildingGroup')}
                            onChange={(event: SelectChangeEvent<string>) => setFieldValue('buildingGroup', event.target.value as string)}
                            input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="subtitle1">Укажите адрес ЖК</Typography>;
                              }

                              return <Typography variant="subtitle2">{selected}</Typography>;
                            }}
                          >
                            {building.buildingGroups.map((column: BuildingGroup) => (
                              <MenuItem key={column.id} value={column.title}>
                                <ListItemText primary={column.title} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.buildingGroup && errors.buildingGroup && (
                          <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                            {errors.buildingGroup}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="entrances">Количество подъездов</InputLabel>
                        <TextField
                          inputProps={{ type: 'number', min: 1, max: 20 }}
                          fullWidth
                          id="entrances"
                          rows={2}
                          {...getFieldProps('entrances')}
                          // error={Boolean(touched.address && errors.address)}
                          // helperText={touched.address && errors.address}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Отмена
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      {buildingGroup ? 'Редактировать' : 'Добавить'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && !error ? (
        <AlertBuildingGroupDelete id={buildingGroup.id} error={error} open={openAlert} handleClose={handleAlertClose} />
      ) : (
        <AlertBuildingDeleteFail open={openAlert} handleClose={handleAlertClose} />
      )}
    </>
  );
};

export default AddBuilding;
