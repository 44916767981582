import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';

// types
import { INewBuildingGroup, BuildingGroupProps, BuildingGroup } from 'types/buildingGroup';

//helpers
import { randomIntFromInterval } from 'utils/randomIntFromInterval';

const initialState: BuildingGroupProps = {
  buildingGroups: [],
  buildingGroup: null,
  error: false,
  isLoader: false,
  isModalOpen: false,
  rerender: 0
};

const buildingsGroupsSlice = createSlice({
  name: 'buildingGroups',
  initialState,
  reducers: {
    // loader
    loading(state) {
      state.isLoader = true;
    },

    // error
    hasError(state, action) {
      state.isLoader = false;
      state.error = action.payload;
    },

    // building list
    setBuildingGroups(state, action) {
      state.isLoader = false;
      state.buildingGroups = action.payload;
    },

    setBuildingGroup(state, action: PayloadAction<BuildingGroup | null>) {
      state.isLoader = false;
      state.buildingGroup = action.payload;
    },
    // // create event
    createEvent(state) {
      state.isLoader = false;
      state.isModalOpen = false;
    },

    // delete BuildingGroup
    deleteBuildingGroup(state, action) {
      const { eventId } = action.payload;
      state.isModalOpen = false;
      state.buildingGroups = state.buildingGroups.filter((b) => b.id !== eventId);
    },

    rerender(state) {
      state.rerender = randomIntFromInterval();
    }
  }
});

export default buildingsGroupsSlice.reducer;

export const { setBuildingGroups, setBuildingGroup } = buildingsGroupsSlice.actions;
const token = `Bearer ${localStorage.getItem('serviceToken')}`;

const headers = { Authorization: token, 'content-type': 'application/ld+json; charset=utf-8' };

export function getBuildingsGroups() {
  return async () => {
    dispatch(buildingsGroupsSlice.actions.loading());
    try {
      //TODO
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/building_groups`);

      dispatch(buildingsGroupsSlice.actions.setBuildingGroups(response.data['hydra:member']));
    } catch (error) {
      dispatch(buildingsGroupsSlice.actions.hasError(error));
    }
  };
}

export function getBuildingGroup(id: string) {
  return async () => {
    dispatch(buildingsGroupsSlice.actions.loading());
    try {
      //TODO
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/building_groups/${id}`);

      dispatch(buildingsGroupsSlice.actions.setBuildingGroup(response.data));
    } catch (error) {
      dispatch(buildingsGroupsSlice.actions.hasError(error));
    }
  };
}

export function createBuildingsGroups(newEvent: INewBuildingGroup) {
  return async () => {
    dispatch(buildingsGroupsSlice.actions.loading());
    try {
      //TODO
      await axios.post(`${process.env.REACT_APP_API_URL}/building_groups`, newEvent, { headers });

      dispatch(buildingsGroupsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsGroupsSlice.actions.hasError(error));
    }
  };
}

export function updateBuildingsGroups(id: number, newEvent: INewBuildingGroup) {
  return async () => {
    dispatch(buildingsGroupsSlice.actions.loading());
    try {
      //TODO env
      await axios.put(`${process.env.REACT_APP_API_URL}/building_groups/${id}`, newEvent, { headers });

      dispatch(buildingsGroupsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsGroupsSlice.actions.hasError(error));
    }
  };
}

export function deleteBuildingsGroups(id: string) {
  return async () => {
    dispatch(buildingsGroupsSlice.actions.loading());
    dispatch(buildingsGroupsSlice.actions.hasError(false));
    try {
      //TODO env
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/building_groups/${id}`, { headers });
      //@ts-ignore
      if (response['hydra:description']) {
        return dispatch(buildingsGroupsSlice.actions.hasError(true));
      }

      dispatch(buildingsGroupsSlice.actions.deleteBuildingGroup({ id }));
      dispatch(buildingsGroupsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsGroupsSlice.actions.hasError(error));
    }
  };
}
