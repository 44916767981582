import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import Profile from './Profile';

const HeaderContent = () => {
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      <Box width={'100%'} />
      {!downLG && <Profile />}
    </>
  );
};

export default HeaderContent;
