import { FormattedMessage } from 'react-intl';
import { CalendarOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';
const icons = {
  CalendarOutlined
};

export const exams: NavItemType = {
  id: 'exams',
  title: <FormattedMessage id="Проверки" />,
  type: 'group',
  children: [
    {
      id: 'exam',
      title: <FormattedMessage id="План проверок" />,
      type: 'item',
      url: '/exams',
      icon: icons.CalendarOutlined
    },
    {
      id: 'moderation',
      title: <FormattedMessage id="Модерация" />,
      type: 'item',
      url: '/moderation',
      icon: icons.CalendarOutlined
    },
    {
      id: 'verification',
      title: <FormattedMessage id="Верификация" />,
      type: 'item',
      url: '/verification',
      icon: icons.CalendarOutlined
    },
    {
      id: 'appelation',
      title: <FormattedMessage id="Апелляция" />,
      type: 'item',
      url: '/appelation',
      icon: icons.CalendarOutlined
    },
    {
      id: 'accepted',
      title: <FormattedMessage id="Принято" />,
      type: 'item',
      url: '/accepted',
      icon: icons.CalendarOutlined
    }
  ]
};

export const examsForModerator: NavItemType = {
  id: 'exams',
  title: <FormattedMessage id="Проверки" />,
  type: 'group',
  children: [
    {
      id: 'moderation',
      title: <FormattedMessage id="Модерация" />,
      type: 'item',
      url: '/moderation',
      icon: icons.CalendarOutlined
    },
    {
      id: 'verification',
      title: <FormattedMessage id="Верификация" />,
      type: 'item',
      url: '/verification',
      icon: icons.CalendarOutlined
    },
    {
      id: 'appelation',
      title: <FormattedMessage id="Апелляция" />,
      type: 'item',
      url: '/appelation',
      icon: icons.CalendarOutlined
    },
    {
      id: 'accepted',
      title: <FormattedMessage id="Принято" />,
      type: 'item',
      url: '/accepted',
      icon: icons.CalendarOutlined
    }
  ]
};

export const examsForClient: NavItemType = {
  id: 'exams',
  title: <FormattedMessage id="Проверки" />,
  type: 'group',
  children: [
    {
      id: 'verification',
      title: <FormattedMessage id="Верификация" />,
      type: 'item',
      url: '/verification',
      icon: icons.CalendarOutlined
    },
    {
      id: 'appelation',
      title: <FormattedMessage id="Апелляция" />,
      type: 'item',
      url: '/appelation',
      icon: icons.CalendarOutlined
    },
    {
      id: 'accepted',
      title: <FormattedMessage id="Принято" />,
      type: 'item',
      url: '/accepted',
      icon: icons.CalendarOutlined
    }
  ]
};
