import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import {
  IState,
  IResponse,
  IChecklist,
  INewChecklist,
  IClient,
  IQuestionGroup,
  INewQuestionGroup,
  IQuestion,
  INewQuestion,
  IEditQuestion
} from 'types/checklists';
import { IError } from 'types';

const initialState: IState = {
  checklists: [],
  currnetChecklist: null,
  questionGroups: [],
  questions: [],
  clients: [],
  listenerQuestionGroups: false,
  loading: false,
  error: null
};

const checklistsSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    allChecklists(state, action: PayloadAction<IChecklist[]>) {
      state.checklists = action.payload;
    },

    allClients(state, action: PayloadAction<IClient[]>) {
      state.clients = action.payload;
    },

    allQuestionGroups(state, action: PayloadAction<IQuestionGroup[]>) {
      state.questionGroups = action.payload;
    },

    allQuestions(state, action: PayloadAction<IQuestion[]>) {
      state.questions = action.payload;
    },

    newChecklist(state, action: PayloadAction<IChecklist>) {
      state.checklists = [action.payload, ...state.checklists];
      state.currnetChecklist = action.payload;
    },

    editChecklist(state, action: PayloadAction<IChecklist>) {
      state.checklists = [action.payload, ...state.checklists.filter((item: IChecklist) => (item.id = action.payload.id))];
      state.currnetChecklist = action.payload;
    },

    setCurrentChecklist(state, action: PayloadAction<IChecklist | null>) {
      state.currnetChecklist = action.payload;
    },

    newQuestionGroup(state, action: PayloadAction<IQuestionGroup>) {
      state.questionGroups = [...state.questionGroups, action.payload];
    },

    editQuestionGroup(state: IState, action: PayloadAction<IQuestionGroup>) {
      state.listenerQuestionGroups = !state.listenerQuestionGroups;
    },

    newQuestion(state: IState, action: PayloadAction<IQuestion>) {
      state.questions = [...state.questions, action.payload];
      if (state.currnetChecklist) {
        let current = state.currnetChecklist;
        const newQuestions = [...current.questions, action.payload];
        current.questions = newQuestions;
      }
    },

    editQuestion(state, action: PayloadAction<IQuestion>) {
      state.questions = [action.payload, ...state.questions.filter((item: IQuestion) => item.id !== action.payload.id)];
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    removeChecklist(state, action: PayloadAction<number>) {
      state.checklists = state.checklists.filter((item: IChecklist) => item.id !== action.payload);
    },

    removeQuestion(state: IState, action: PayloadAction<number>) {
      if (state.currnetChecklist) {
        let current = state.currnetChecklist;
        const newQuestions = current.questions.filter((item: IQuestion) => item['@id'] !== `/questions/${action.payload}`);
        current.questions = newQuestions;
      }
    },

    removeQuestionGroup(state, action: PayloadAction<number>) {
      state.questionGroups = state.questionGroups.filter((item: IQuestionGroup) => item.id !== action.payload);
    },

    setError(state: IState, action: PayloadAction<IError | null>) {
      state.error = action.payload;
    }
  }
});

export default checklistsSlice.reducer;

export function getChecklists() {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IChecklist[]>>(`${process.env.REACT_APP_API_URL}/checklists`);
      dispatch(checklistsSlice.actions.allChecklists(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function getChecklist(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IChecklist>(`${process.env.REACT_APP_API_URL}/checklists/${id}`);
      dispatch(checklistsSlice.actions.setCurrentChecklist(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export const { setCurrentChecklist, setError, allQuestionGroups } = checklistsSlice.actions;

export function getClients() {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IClient[]>>(`${process.env.REACT_APP_API_URL}/clients`);
      dispatch(checklistsSlice.actions.allClients(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function getQuestionGroups() {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IQuestionGroup[]>>(`${process.env.REACT_APP_API_URL}/question_groups`);
      dispatch(checklistsSlice.actions.allQuestionGroups(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function getCurrentQuestionGroups(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IQuestionGroup[]>>(`${process.env.REACT_APP_API_URL}/question_groups?checklist.id=${id}`);
      dispatch(checklistsSlice.actions.allQuestionGroups(response.data['hydra:member']));
      return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function getCurrentQuestions(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IQuestion[]>>(`${process.env.REACT_APP_API_URL}/questions?questionGroup.id=${id}`);
      dispatch(checklistsSlice.actions.allQuestions(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function getQuestions() {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IQuestion[]>>(`${process.env.REACT_APP_API_URL}/questions`);
      dispatch(checklistsSlice.actions.allQuestions(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function createChecklist(body: INewChecklist) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.post<IChecklist>(`${process.env.REACT_APP_API_URL}/checklists`, body);
      dispatch(checklistsSlice.actions.newChecklist(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function updateChecklist(body: INewChecklist, id: number) {
  const { title, client } = body;
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.patch<IChecklist>(
        `${process.env.REACT_APP_API_URL}/checklists/${id}`,
        { title, client },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      );
      dispatch(checklistsSlice.actions.editChecklist(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function createQuestionGroup(body: INewQuestionGroup) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.post<IQuestionGroup>(`${process.env.REACT_APP_API_URL}/question_groups`, body);
      dispatch(checklistsSlice.actions.newQuestionGroup(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function updateQuestionGroup(id: number, title: string) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.patch<IQuestionGroup>(
        `${process.env.REACT_APP_API_URL}/question_groups/${id}`,
        { title },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      );
      dispatch(checklistsSlice.actions.editQuestionGroup(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function createQuestion(body: INewQuestion) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.post<IQuestion>(`${process.env.REACT_APP_API_URL}/questions`, body);
      dispatch(checklistsSlice.actions.newQuestion(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function updateQuestion(body: IEditQuestion, id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      const response = await axios.patch<IQuestion>(`${process.env.REACT_APP_API_URL}/questions/${id}`, body, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      });
      dispatch(checklistsSlice.actions.editQuestion(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function deleteChecklist(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/checklists/${id}`);
      dispatch(checklistsSlice.actions.removeChecklist(id));
    } catch (error) {
      error && dispatch(checklistsSlice.actions.setError(error as IError));
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function deleteQuestion(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/questions/${id}`);
      dispatch(checklistsSlice.actions.removeQuestion(id));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}

export function deleteQuestionGroup(id: number) {
  return async () => {
    dispatch(checklistsSlice.actions.setLoading(true));
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/question_groups/${id}`);
      dispatch(checklistsSlice.actions.removeQuestionGroup(id));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(checklistsSlice.actions.setLoading(false));
    }
  };
}
