import other from './other';
import { exams, examsForModerator, examsForClient } from './exams';

// types
import { NavItemType } from 'types/menu';

const menuItems: { items: NavItemType[] } = {
  items: [exams, other]
};

export const menuItemsForModerator: { items: NavItemType[] } = {
  items: [examsForModerator]
};

export const menuItemsForClient: { items: NavItemType[] } = {
  items: [examsForClient]
};

export default menuItems;
