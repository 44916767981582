// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  RocketOutlined,
  BarChartOutlined,
  FileTextOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  StopOutlined,
  SmileOutlined,
  UserOutlined,
  RocketOutlined,
  BarChartOutlined,
  FileTextOutlined
};

const other: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'clients',
      title: <FormattedMessage id="Клиенты" />,
      type: 'item',
      url: '/clients',
      icon: icons.RocketOutlined
    },
    {
      id: 'buildingGroups',
      title: <FormattedMessage id="ЖК" />,
      type: 'item',
      url: '/buildingGroups',
      icon: icons.BarChartOutlined
    },
    {
      id: 'checklists',
      title: <FormattedMessage id="Чек-листы" />,
      type: 'item',
      url: '/checklists',
      icon: icons.FileTextOutlined
    },
    {
      id: 'users',
      title: <FormattedMessage id="Пользователи" />,
      type: 'item',
      url: '/users',
      icon: icons.UserOutlined
    }
  ]
};

export default other;
